import React, { useState } from 'react'
import styled  from 'styled-components';
import { Text } from '../ui/Typography/Typography';
import { TitleH5 } from '../ui/Title/Title';
import { Wrapper } from '../ui/Wrapper/Wrapper';
import Constants from '../../config/Constants';
import Province, { ProvinceValues } from '../../config/Province';
import validator from 'validator';
import { useNavigate } from 'react-router-dom';

const Input = styled('input')`
    padding: 14px 10px;
    width: 100%;
    border: none;
    border-radius: 8px;
`;

const Select = styled('select')`
    width: 100%;
    padding: 14px 10px;
    border: none;
    border-radius: 8px;
`;

const WrapperInput = styled(Wrapper)`
    margin: 6px 5px;
    width: 100%;
`;
const WrapperInputCheck = styled(Wrapper)`
    width: 100%;
    margin: 15px 6px;
    input {
        float: left;
        display: inline-block;
        width: fit-content;
        margin-right: 10px;
        margin-top: 3px;
    }
    ${Text} {
        display: inline-block;
        a {
            color: ${({theme}) => theme.colors.secondary};
        }
    }
`;

const RowForm = styled(Wrapper)`
    width: 100%;
    display: flex;
    @media ${({theme}) => theme.mediaQueries.breakpointMd} {
        flex-direction: column
    }
`;

const BoxForm = styled(Wrapper)`
    background: #f7f7f788;
    border-radius: 15px;
    padding: 30px;
    width: 100%;
`;

const Button = styled('button')`
    margin: 20px 0;
    background: ${({theme}) => theme.colors.secondary};
    display: block;
    border-radius: 40px;
    border: none;
    padding: 20px 35px;
    color: white;
    font-weight: 700;
    max-width: 250px;
    &:hover {
        cursor: pointer;
        background: #0a2998;
    }
`;

interface FormValues {
    nameAgency: string
    vat: string
    email: string
    phone: string
    service: string
    region: string
    province: string
    employees: string
}

interface FormValidation {
    nameAgency: boolean
    vat: boolean
    email: boolean
    phone: boolean
    service: boolean
    region: boolean
    province: boolean
    employees: boolean
}

const formInitialValues = {
    nameAgency: '',
    vat: '',
    email: '',
    phone: '',
    service: '',
    region: '',
    province: '',
    employees: ''
}

const formInitialValidation = {
    nameAgency: true,
    vat: true,
    email: true,
    phone: true,
    service: true,
    region: true,
    province: true,
    employees: true
}

const Form = () => {
    const [form, setForm] = useState<FormValues>(formInitialValues);
    const [formValidation, setFormValidation] = useState<FormValidation>(formInitialValidation);
    const [check, setCheck] = useState<boolean>(false);

    let navigate = useNavigate()

    return (
        <BoxForm id="box-form">
            {renderHeadTitle()}
            {renderForm()}
            {renderButtonForm()}
        </BoxForm>
    );

    function renderForm(){
        return (
            <>
                <RowForm>
                    {renderInputAgencyName()}
                    {renderInputVatNumber()}
                </RowForm>
                <RowForm>
                    {renderInputEmail()}
                    {renderInputPhone()}
                </RowForm>
                <RowForm>
                    {renderSelectService()}
                </RowForm>
                <RowForm>
                    {renderSelectRegion()}
                    {renderSelectProvince()}
                </RowForm>
                <RowForm>
                    {renderSelectEmployees()}
                </RowForm>
                <RowForm>
                    {renderCheckPrivacy()}
                </RowForm>
            </>
        );
    }


    function renderInputAgencyName() {
        return (
            <WrapperInput>
                <Input
                    type="text"
                    placeholder="Nome Azienda"
                    onChange={(e) => setForm({...form, nameAgency: e.currentTarget.value})}
                    onBlur={() => setFormValidation({
                        ...formValidation, 
                        nameAgency: handleValidationLength(form.nameAgency, {min: 3, max: 20})})}/>
                {renderErrorMessage(formValidation.nameAgency, 'Compila il campo Nome Azienda')}
            </WrapperInput>
        );
    }

    function renderInputVatNumber() {
        return (
            <WrapperInput>
                <Input
                    type="text"
                    placeholder="Partita IVA"
                    onChange={(e) => setForm({...form, vat: e.currentTarget.value})}
                    onBlur={() => setFormValidation({
                        ...formValidation, 
                        vat: handleValidationLength(form.vat, {min: 3, max: 24})})}/>
                {renderErrorMessage(formValidation.vat, 'Compila il campo P.IVA')}
            </WrapperInput>
        );
    }

    function renderInputEmail(){
        return (
            <WrapperInput>
                <Input
                    type="text"
                    placeholder="E-mail"
                    onChange={(e) => setForm({...form, email: e.currentTarget.value})}
                    onBlur={() => setFormValidation({...formValidation, email: handleValidationEmail(form.email)})}/>
                {renderErrorMessage(formValidation.email, 'Inserisci una mail valida')}
            </WrapperInput>
        );
    }

    function renderInputPhone(){
        return (
            <WrapperInput>
                <Input
                    type="text"
                    placeholder="Telefono"
                    onChange={(e) => setForm({...form, phone: e.currentTarget.value})}
                    onBlur={() => setFormValidation({
                        ...formValidation, 
                        phone: handleValidationLength(form.phone, {min: 9, max: 11})})}/>
                {renderErrorMessage(formValidation.phone, 'Inserisci un numero di Telefono valido')}
            </WrapperInput>
        );
    }

    function renderSelectService(){
        const options = Constants.SELECT_SERVICE.map((x, i) => (
            <option key={i} value={x}>{x}</option>
        ))
        return (
            <WrapperInput>
                <Select
                    onChange={(e) => setForm({...form, service: e.currentTarget.value})}
                    onBlur={() => setFormValidation({
                        ...formValidation, 
                        service: handleValidationLength(form.service, {min: 3, max: 40})})}>
                    <option value=''>Servizi</option>
                    {options}
                </Select>
                {renderErrorMessage(formValidation.service, 'Compila il campo Servizi')}
            </WrapperInput>
        );
    }

    function renderSelectRegion(){
        const options = Province.regioni.sort().map(x => (
            <option key={x} value={x}>{x}</option>
        ))
        return (
            <WrapperInput>
                <Select
                    onChange={(e) => setForm({...form, region: e.currentTarget.value})}
                    onBlur={() => setFormValidation({
                        ...formValidation, 
                        region: handleValidationLength(form.region, {min: 3, max: 20})})}>
                    <option value=''>Regioni</option>
                    {options}
                </Select>
                {renderErrorMessage(formValidation.region, 'Compila il campo Regione')}
            </WrapperInput>
        )
    }

    function renderSelectProvince(){
        const PROVINCE: ProvinceValues = Province;
        const options = form.region ? PROVINCE[form.region].map((x: any, i: any) => (
            <option key={i} value={x.nome}>{x.nome}</option>
        )) : null;
        return (
            <WrapperInput>
                <Select
                    onChange={(e) => setForm({...form, province: e.currentTarget.value})}
                    onBlur={() => setFormValidation({
                        ...formValidation, 
                        province: handleValidationLength(form.province, {min: 3, max: 20})})}>
                    <option value=''>Province</option>
                    {options}
                </Select>
                {renderErrorMessage(formValidation.province, 'Compila il campo Provincie')}
            </WrapperInput>
        )
    }

    function renderSelectEmployees(){
        const options = Constants.SELECT_EMPLOYEES.map(x => (
            <option key={x} value={x}>{x}</option>
        ))
        return (
            <WrapperInput>
                <Select
                    onChange={(e) => setForm({...form, employees: e.currentTarget.value})}
                    onBlur={() => setFormValidation({
                        ...formValidation, 
                        employees: handleValidationLength(form.employees, {min: 3, max: 20})})}>
                    <option value=''>Numero di dipendenti</option>
                    {options}
                </Select>
                {renderErrorMessage(formValidation.employees, 'Compila il campo Numero di dipendenti')}
            </WrapperInput>
        )
    }

    function renderCheckPrivacy(){
        return (
            <WrapperInputCheck>
                <Input
                    onChange={() => setCheck(!check)}
                    type="checkbox"
                /> 
                <Text>Ho letto l'<a href="https://www.g4vigilanza.it/privacy-clienti-fornitori/" target="blank">informativa sulla privacy</a></Text>
            </WrapperInputCheck>
        );
    }

    function renderButtonForm(){
        return (
            <WrapperInput>
                <Button
                    onClick={() => handleSubmit()}
                    type="submit">
                        RICHIEDI INFORMAZIONI
                </Button>
            </WrapperInput>
        );
    }

    function renderHeadTitle() {
        return (
            <Wrapper margin='20px 0px'>
                <TitleH5 dangerouslySetInnerHTML={{__html: Constants.FORM_TITLE}}/>
                <Text dangerouslySetInnerHTML={{__html: Constants.FORM_SUBTITLE}}/>
            </Wrapper>
        );
    }

    function renderErrorMessage(type: boolean, text: string){
        if (!type) return (
            <Text error small margin={'4px 0px'}>{text}</Text>
        )
    }

    function handleValidationEmail(mail: string){
        if ( !validator.isEmail(mail) ) return false;
        else return true;
    }

    function handleValidationLength(value: string, options: {min: number, max: number}){
        if ( !validator.isByteLength(value, options) ) return false;
        else return true
    }

    

    async function handleSubmit(){
        const valuesIsEmpty = await Object.values(form).find(x => x === '') === undefined ? false : true;
        const endpoint = 'https://hooks.zapier.com/hooks/catch/3939523/b1txayp/';
        const date = new Date();
        const newDate = `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
        try {
            if (valuesIsEmpty) throw Error('compila il form per continuare');
            if (!check) throw Error('leggi e accetta l\'informativa privacy');
            await fetch(endpoint, {
                method: 'POST',
                body: JSON.stringify({...form, date: newDate})
            })
            .then(data => {
                if (data.status === 200) {
                    navigate('thank-you') 
                }
            });
        } catch (error) {
            alert(error)
        }
    }
}

export default Form;