import React from 'react';
import { Wrapper } from '../ui/Wrapper/Wrapper';
import styled from 'styled-components';
import { Container, Row, Col } from '../ui/GridSystem/GridSystem';
import { TitleH4} from '../ui/Title/Title';
import Constants from '../../config/Constants';
import CardServicesDue from '../CardServiceDue/CardServiceDue';


const WrapperSection = styled(Wrapper)`
    width: 100%;
    padding: 40px 0px;
    background: #f7f7f7;
`;


const Servizi = () => {
    
    return (
        <WrapperSection>
            <Container>
                <Row>
                    {renderTitle()}
                    {renderService()}
                </Row>
            </Container>
        </WrapperSection>
    );
    
    function renderTitle() {
        return (
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <TitleH4 dangerouslySetInnerHTML={{__html: Constants.SERVICES_TITLE}}/>
            </Col>
        )
    }
    function renderService(){
        return Constants.SERVICES.map((x, i) => i < 4 ? (
            <Col key={i} xl={6} lg={6} md={6} sm={12} xs={12}>
                <CardServicesDue
                    img={x.img}
                    title={x.title}
                    purpose={x.purpose}
                    category={x.category}
                    tech={x.tech}
                    discover={x.discover}
                />
            </Col>
        ): null);
    }
    }

export default Servizi;