import React from 'react';
import styled from 'styled-components';
import { getLocalImage } from '../../helpers/getLocalImages';
import { Col } from '../ui/GridSystem/GridSystem';
import { Wrapper } from '../ui/Wrapper/Wrapper';
import { Container, Row } from '../ui/GridSystem/GridSystem';

const FooterStyled = styled('footer')`
    width: 100%;
    background: #f7f7f7;
    height: 80px;
`;
const WrapperImage = styled(Wrapper)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    img {
        width: 200px;
    }
    @media ${({theme}) => theme.mediaQueries.breakpointSm} {
        .logo_1 {
            width: 130px;
        }
    }
`;

const WrapperText = styled(Wrapper)`
    width: 100%;
    display: flex;
    justify-content: end;
    height: 100%;
    align-items: center;
    @media ${({theme}) => theme.mediaQueries.breakpointMd} {
        justify-content: start;
    }
    a {
        pointer-events: auto;
        align-items: center;
        padding: 0px 10px;
        color: ${({theme}) => theme.colors.secondary};
        text-decoration: none;
    }
    a:hover {
        cursor: pointer;
        color: #0a2998;
    }
`;

function Footer() {
    return (
        <FooterStyled>
            <Container>
                <Row>
                    {renderLogo()}
                    {renderInformativaPrivacy()}
                </Row>
            </Container>
        </FooterStyled>
    );

    function renderLogo(){
        return (
            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                <WrapperImage>
                    <img src={getLocalImage('g4_logo_1.png')} alt="g4-logo" className={'logo_1'}/>
                </WrapperImage>
            </Col>
        )
    }

    function renderInformativaPrivacy(){
        return (
            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                <WrapperText>
                    <a href="https://www.g4vigilanza.it/privacy-clienti-fornitori/" target={'blank'}>Informativa Privacy</a>
                    <a href="https://www.g4vigilanza.it/cookies-3/" target={'blank'}> Cookie Policy </a>
                </WrapperText>
            </Col>
        )
    }

};


export default Footer; 

