import React from 'react';
import { Wrapper } from '../ui/Wrapper/Wrapper';
import styled from 'styled-components';
import { Container, Row, Col } from '../ui/GridSystem/GridSystem';
import Constants from '../../config/Constants';
import BulletPoint from '../BulletPoint/BulletPoint';


const WrapperSection = styled(Wrapper)`
    width: 100%;
    height: 100%;
    padding: 40px 0px;
    background: #f7f7f7;
`;

const BulletPoints = () => {
    
    return (
        <WrapperSection>
            <Container>
                <Row>
                    {renderBulletPoints()}
                </Row>
            </Container>
        </WrapperSection>
    );
    function renderBulletPoints(){
        return Constants.BULLET_POINTS.map((x, i) => i < 4 ? (
            <Col key={i} xl={4} lg={4} md={4 } sm={12} xs={12}>
                <BulletPoint
                    img={x.img}
                    title={x.title}
                    text={x.text}
                />
            </Col>
        ): null);
    }
}

export default BulletPoints;