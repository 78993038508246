// eslint-disable-next-line
export default {
    HERO_TITLE: 'La tua attività è preziosa. <br> Proteggila.',
    HERO_SUBTITLE: 'I più elevati sistemi tecnologici di sicurezza. <br> Da oltre 40 anni al fianco delle aziende.',

    VANTAGGI_TITLE: 'Vigilanza e sicurezza, ancora più affidabili. <br> Scopri i vantaggi:',
    VANTAGGI_TEXT: "Cash Management più sicuro <br><br> Servizi su misura a seconda delle esigenze specifiche <br><br> Elasticità operativa durante l'installazione <br><br> Assistenza tecnica 24/24",
    VANTAGGI_LIST: [
        "Cash Management più sicuro",
        "Servizi su misura a seconda delle esigenze specifiche",
        "Elasticità operativa durante l'installazione",
        "Assistenza tecnica 24/24"
    ],
    SERVICES_TITLE: 'I 4 servizi più richiesti dalle aziende',
    SERVICES: [
        {
            img: 'piantonamento.png',
            title: 'Piantonamento',
            purpose: 'Impedire furti, rapine, rapimenti, mantenere l’ordine pubblico',
            category: 'Per Banche, uffici postali, aziende, negozi, eventi, PA',
            tech: 'Con GPG (Guardie Giurate)',
            discover: '',
        },
        {
            img: 'videosorveglianza.png',
            title: 'Allarmi e Videosorveglianza',
            purpose: 'Impianto, installazione e assistenza programmata per garantire eccezionale sorveglianza',
            category: 'Per Aziende, Negozi, PA',
            tech: 'Con impianto (centralina e sensori), Telecamere di ultima generazione, Centrale Operativa, software di video-analisi (VCA)',
            discover: '<a href="https://www.g4vigilanza.it/tecnologie/" target="_blank">Scopri di più.</a>',
        },
        {
            img: 'cashmanagement.png',
            title: 'Cash Management (Cash Box Solutions) e Trasporto Valori',
            purpose: 'Gestire il contante (incassi) velocemente e in sicurezza',
            category: 'Per Aziende, Banche, Negozi, PA',
            tech: 'Con casseforti intelligenti, GPG addestrate al trasporto valori, Automezzi blindati ed allestiti per il CIT (Cash In Transit), apparecchiature per la conta certificate BCE',
            discover: '',
        },
        {
            img: 'accoglienza.png',
            title: 'Accoglienza e Reception (Portierato)',
            purpose: 'Accogliere visitatori, monitorare e gestire ingressi e uscite',
            category: 'Per Grandi Aziende, Eventi, PA',
            tech: 'Con personale non armato',
            discover: '',
        },
    ],

    BULLET_POINTS: [
        {
            img:'warning.png',
            title:'Impianti antintrusione',
            text:'I nostri impianti consentono la massima sicurezza grazie al costante dialogo tra l\'impianto e la Centrale Operativa, previa installazione di una periferica di trasmissione con la nostra struttura.',
        },
        {
            img:'camera.png',
            title:'Videosorveglianza',
            text:'Soluzioni tecnologiche all\'avanguardia, per il massimo della sicurezza possibile. Gli impianti sono adattabili sulla base di esigenze specifiche. <a href="https://www.g4vigilanza.it/tecnologie/" target="_blank">Scopri di più.</a>',
        },
        {
            img:'vigilance.png',
            title:'Telecontrollo',
            text:'Il servizio viene svolto dalla Centrale Operativa e da Guardie Giurate che monitorano costantemente gli impianti antintrusione per mezzo dei collegamenti delle periferiche (come radio e GPS).',
        }
    ],
    
    ABOUTUS_TITLE1: '40 anni di esperienza',
    ABOUTUS_TITLE2: 'Servizi di sicurezza integrati',
    ABOUTUS_TEXT1: 'G4 Vigilanza inizia la propria attività nel <b>1980</b>. <br><br> Negli anni l’Istituto ha adeguato i propri strumenti tecnologici e operativi nel rispetto dei <b>cambiamenti del mercato</b>. <br><br> Gli investimenti sostenuti nella ricerca e nello sviluppo di nuove soluzioni tecniche hanno condotto all’impiego sempre maggiore dei <b>più evoluti sistemi telematici di controllo</b>. <br><br> Ciò ha consentito di <b>migliorare la qualità</b> del presidio del territorio supportando la Guardia Particolare Giurata nello svolgimento della propria attività di vigilanza. <br><br> Il controllo da remoto, in generale, è la tecnologia che ha consentito di offrire <b>servizi sempre più sicuri e utili</b>, accrescendo <b>l’efficacia e la velocità di intervento</b> in caso di necessità.',
    ABOUTUS_TEXT2: 'G4 Vigilanza S.p.A. offre, anche in forma integrata, un’ampia gamma di servizi di <b>Security</b> (vigilanza armata, videosorveglianza, trasporto valori, installazione, gestione e manutenzione di impianti di sicurezza) e servizi di <b>Facility Management</b> (pulizie, servizi fiduciari, di accoglienza e portierato e servizi logistici). <br><br> Il nostro gruppo negli anni ha sviluppato ed implementato piattaforme gestionali web-based, interamente di proprietà, per consentire la <b>integrale governance operativa delle commesse</b>, garantendo ai propri clienti il monitoraggio dei servizi in tempo reale, con conservazione dei dati per fini statistici e di controllo',

    FORM_TITLE: 'Richiedi informazioni',
    FORM_SUBTITLE: 'Compila il form per richiedere informazioni',
    
    SELECT_SERVICE: [
        'Piantonamento',
        'Allarmi e videosorveglianza',
        'Cash management e trasporto valori',
        'Accoglienza e reception',
        'Vigilanza ispettiva',
    ],

    SELECT_EMPLOYEES: [
        'Meno di 5',
        '6-20',
        '21-50',
        '+50'
    ],

    NUMBERS: [
        {
            title: '25.000',
            subtitle: 'Clienti'
        },
        {
            title: '500',
            subtitle: 'Addetti'
        },
        {
            title: '11',
            subtitle: 'Sedi Operative'
        },
        {
            title: '180',
            subtitle: 'Mezzi'
        },
        {
            title: '600',
            subtitle: 'Interventi al mese'
        },
        {
            title: '6',
            subtitle: 'Certificazioni'
        },
    ],

    THANKYOU_TITLE: 'Grazie per aver compilato il form.',
    THANKYOU_TEXT: 'A breve riceverai le informazioni richieste.', 
};