import React from 'react';
import { Wrapper } from '../ui/Wrapper/Wrapper';
import styled from 'styled-components';
import { Container, Row, Col } from '../ui/GridSystem/GridSystem';
import { TitleH4 } from '../ui/Title/Title';
import { Text } from '../ui/Typography/Typography';
import Constants from '../../config/Constants';
import { getLocalImage } from '../../helpers/getLocalImages';


const WrapperSection = styled(Wrapper)`
    width: 100%;
    padding: 40px;
`;
const BoxImage = styled(Wrapper)`
    width: 100%;
    height: 100%;
    padding: 50px 0;
    display: flex;
    align-items: center;
    img {
        width: 100%;
    }
`;
const BoxTitle = styled(Wrapper)`
    width: 100%;
    height: 100%;
    h4 {
        margin-bottom: 50px;
    }
    .title_2 {
        margin-top: 50px;
    }
    button {
        background: ${({theme}) => theme.colors.secondary};
        display: flex;
        border-radius: 40px;
        border: none;
        max-width: 250px;
        justify-content: center;
        margin-top: 50px;
        width: 90%;
        a {
            pointer-events: auto;
            display: flex;
            align-items: center;
            padding: 20px 30px;
            color: white;
            text-decoration: none;
            font-weight: bold;
        }
    }
    button:hover {
        cursor: pointer;
        background: #0a2998;
    }
`;

const ButtonBox = styled(Wrapper)`
    @media ${({theme}) => theme.mediaQueries.breakpointLg} {
        display: flex;
        justify-content: center;
    }
`;

const AboutUs = () => {
    
    return (
        <WrapperSection>
            <Container>
                <Row>
                    {renderColumnTitle()}
                    {renderColumnImg()}
                </Row>
            </Container>
        </WrapperSection>
    );

    function renderColumnImg() {
        return (
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <BoxImage>
                    <img src={getLocalImage('section_aboutus.png')} alt={'Vantaggi G4'} />
                </BoxImage>
            </Col>
        )
    }

    function renderColumnTitle(){
        return (
            <Col xl={6} lg={6} md={6} sm={12} xs={12} align={'center'}>
                <BoxTitle>
                    <TitleH4 margin={"0px 0px 10px 0px"} dangerouslySetInnerHTML={{__html: Constants.ABOUTUS_TITLE1}}/>
                    <Text dangerouslySetInnerHTML={{__html: Constants.ABOUTUS_TEXT1}}/>
                    <ButtonBox>
                        <button><a  href="#box-form">RICHIEDI INFORMAZIONI</a></button>
                    </ButtonBox>
                </BoxTitle>
            </Col>
        )
    }
    
}
export default AboutUs;