import React from 'react'
import styled from 'styled-components';
import { Container, Row, Col } from '../components/ui/GridSystem/GridSystem';
import { TitleH2 } from '../components/ui/Title/Title';
import { Text } from '../components/ui/Typography/Typography';
import { Wrapper } from '../components/ui/Wrapper/Wrapper';
import Constants from '../config/Constants';
import { getLocalImage } from '../helpers/getLocalImages';

const SectionStyled = styled(Wrapper)`
    padding: 0px;
    background-position: center;
    width: 100%;
    height: 100vh;
`;

const BoxLogo = styled('div')`
    margin: 20px 0;
    text-align: center;
    width: 100%;
    img {
        max-width: 300px;
    }
`;

export default function ThankYou() {
    return (
        <SectionStyled bgImage={getLocalImage('batman.png')} bgCover flex align='center'>
            <Container>
                <Row>
                    {renderColumnLeft()}
                    {renderColumnRight()}
                </Row>
            </Container>
        </SectionStyled>
    );

    function renderColumnLeft(){
        return (
            <Col xl={6} lg={6} md={6} sm={12} xs={12} align={'center'}>
                <Wrapper width='100%' height='auto'>
                    <TitleH2 white dangerouslySetInnerHTML={{__html: Constants.THANKYOU_TITLE}}/>
                    <Text large white margin="20px 0 0 0" dangerouslySetInnerHTML={{__html: Constants.THANKYOU_TEXT}}/>
                </Wrapper>
            </Col>
        )
    }

    function renderColumnRight() {
        return (
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <BoxLogo>
                    <img src={getLocalImage('g4_logo_1.png')} alt="g4-logo" className={'logo_1'}/>
                </BoxLogo>
            </Col>
        )
    }
}
