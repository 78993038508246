import React from "react";
import styled, {keyframes} from "styled-components";
import { Wrapper } from "../ui/Wrapper/Wrapper";
import { getLocalImage } from "../../helpers/getLocalImages";

const SliderContainer = styled(Wrapper)`
    align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
    height: 100px;
	margin: 30px auto 80px auto;
	overflow:hidden;
	position: relative;

        &::before,
        &::after {
            @include white-gradient;
            content: "";
            height: 100px;
            position: absolute;
            width: 200px;
            z-index: 2;
        }
        
        &::after {
            right: 0;
            top: 0;
            transform: rotateZ(180deg);
        }

        &::before {
            left: 0;
            top: 0;
        } 
`;

const animationScroll = keyframes`
    0% { transform: translateX(0); }
    100% { transform: translateX(calc(-250px * 7))}
`;

const SliderTrack = styled(Wrapper)`
    animation: ${animationScroll} 30s linear infinite;
    display: flex;
    width: calc(250px * 14);
    img {
        padding: 0 10px;
        height: 120px; 
    }
`;


const CarouselLoghi = () => {
    return (
        <SliderContainer>
            <SliderTrack>
                <img src={getLocalImage('1.png')} alt="logo1"/>
                <img src={getLocalImage('2.png')} alt="logo2"/>
                <img src={getLocalImage('3.jpg')} alt="logo3"/>
                <img src={getLocalImage('4.jpg')} alt="logo4"/>
                <img src={getLocalImage('5.gif')} alt="logo5"/>
                <img src={getLocalImage('6.png')} alt="logo6"/>
                <img src={getLocalImage('7.png')} alt="logo7"/>
                <img src={getLocalImage('8.png')} alt="logo8"/>
                <img src={getLocalImage('9.png')} alt="logo9"/>
                <img src={getLocalImage('10.jpg')} alt="logo10"/>
                <img src={getLocalImage('11.jpg')} alt="logo11"/>
                <img src={getLocalImage('12.jpg')} alt="logo12"/>
                <img src={getLocalImage('13.png')} alt="logo13"/>
                <img src={getLocalImage('14.png')} alt="logo14"/>
                <img src={getLocalImage('15.png')} alt="logo15"/>
                <img src={getLocalImage('16.jpg')} alt="logo16"/>
                <img src={getLocalImage('17.png')} alt="logo17"/>
                <img src={getLocalImage('18.png')} alt="logo18"/>
                <img src={getLocalImage('19.png')} alt="logo19"/>
                <img src={getLocalImage('20.png')} alt="logo20"/>
                <img src={getLocalImage('21.png')} alt="logo21"/>
                <img src={getLocalImage('22.png')} alt="logo22"/>
                <img src={getLocalImage('23.png')} alt="logo23"/>
                <img src={getLocalImage('24.png')} alt="logo24"/>
                <img src={getLocalImage('25.jpg')} alt="logo25"/>
                <img src={getLocalImage('26.png')} alt="logo26"/>
                <img src={getLocalImage('27.jpg')} alt="logo27"/>
                <img src={getLocalImage('28.jpg')} alt="logo28"/>
                <img src={getLocalImage('29.png')} alt="logo29"/>
                <img src={getLocalImage('31.jpg')} alt="logo31"/>
                <img src={getLocalImage('32.jpg')} alt="logo32"/>
                <img src={getLocalImage('33.png')} alt="logo33"/>
                <img src={getLocalImage('34.png')} alt="logo34"/>
                <img src={getLocalImage('35.png')} alt="logo35"/>
                <img src={getLocalImage('36.png')} alt="logo36"/>
                <img src={getLocalImage('37.png')} alt="logo37"/>
                <img src={getLocalImage('38.jpg')} alt="logo38"/>
                <img src={getLocalImage('39.jpg')} alt="logo39"/>
                <img src={getLocalImage('40.png')} alt="logo40"/>
                <img src={getLocalImage('41.png')} alt="logo41"/>
                <img src={getLocalImage('42.png')} alt="logo42"/>
                <img src={getLocalImage('43.jpg')} alt="logo43"/>
                <img src={getLocalImage('44.jpg')} alt="logo44"/>
            </SliderTrack>
        </SliderContainer>
    );
}

export default CarouselLoghi