import React from 'react';
import { Wrapper } from '../ui/Wrapper/Wrapper';
import styled from 'styled-components';
import { getLocalImage } from '../../helpers/getLocalImages';
import { Container, Row, Col } from '../ui/GridSystem/GridSystem';
import { Text } from '../ui/Typography/Typography';

const WrapperCard = styled(Wrapper)`
    background-size: cover;
    background-position: center center;
    background-color: ${({theme}) => theme.colors.secondary};
    background-blend-mode: soft-light;
    margin: 30px 0;
    border-radius: 20px;
    box-shadow: #8080802e 2px 2px 10px 0px;
    a {
        color: white;
    }
    a:hover {
        color: ${({theme}) => theme.colors.light};
    }
    button {
        background: white;
        display: flex;
        border-radius: 40px;
        border: none;
        max-width: 250px;
        justify-content: center;
        a {
            pointer-events: auto;
            display: flex;
            align-items: center;
            padding: 20px 30px;
            color: ${({theme}) => theme.colors.secondary};
            text-decoration: none;
            font-weight: bold;
        }
        a:hover {
            color: white;
        }
    }
    button:hover {
        cursor: pointer;
        background: #b3b3b3;
    }
`;
const Card = styled(Wrapper)`
    min-height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    hr {
        border: 0.2px solid white;
        margin: 5px 0;
    }
`;

const ButtonBox = styled(Wrapper)`
    display: flex;
    justify-content: center;
`;
const TextBox = styled(Wrapper)`
`;


interface CardServicesDueProps {
    img: string
    title: string
    purpose: string 
    category: string
    tech: string
    discover: string
}

const CardServicesDue: React.FC<CardServicesDueProps> = (props) => {
    return (
        <WrapperCard padding={'30px'} margin={'10px 0px'} bgImage={getLocalImage(props.img)}>
            <Container>
                <Row>
                    {renderBodyCard()}
                </Row>
            </Container>
        </WrapperCard>
    );

    function renderBodyCard(){
        return (
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Card padding={'5px'}>
                    <TextBox>
                        <Text large weight={'600'}white padding={'5px 0'}> {props.title} </Text>
                        <Text white padding={'5px 0'} margin={'10px 0 0 0'}>{props.purpose}</Text>
                        <hr />
                        <Text white padding={'5px 0'}>{props.category}</Text>
                        <hr />
                        <Text white padding={'5px 0'}>{props.tech}</Text>
                    </TextBox>
                    <Text dangerouslySetInnerHTML={{__html: props.discover}}/>
                    <ButtonBox>
                        <button><a href="#box-form">RICHIEDI INFORMAZIONI</a></button>
                    </ButtonBox>
                </Card>
            </Col>
        )
    }
}

export default CardServicesDue;
