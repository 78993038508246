import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PageHome, ThankYou } from './pages';

function App() {
	
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<PageHome />}/>
				<Route path='thank-you' element={<ThankYou />}/>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
