import React from 'react';
import { Wrapper } from '../ui/Wrapper/Wrapper';
import styled from 'styled-components';
import { Container, Row, Col } from '../ui/GridSystem/GridSystem';
import { TitleH4 } from '../ui/Title/Title';
import Constants from '../../config/Constants';
import { getLocalImage } from '../../helpers/getLocalImages';


const WrapperSection = styled(Wrapper)`
    width: 100%;
    margin-top: 0px;
    padding: 40px 0px;
`;
const BoxImage = styled(Wrapper)`
    width: 100%;
    display: flex;
    justify-content: center;
    img {
        max-width: 80%;
        padding: 20px 0;
    }
    
`;
const BoxTitle = styled(Wrapper)`
    width: 100%;
    line-height: 50px;
    button {
        background: ${({theme}) => theme.colors.secondary};
        display: flex;
        border-radius: 40px;
        border: none;
        width: 250px;
        justify-content: center;
        margin-top: 20px;
        a {
            pointer-events: auto;
            display: flex;
            align-items: center;
            padding: 20px 30px;
            color: white;
            text-decoration: none;
            font-weight: bold;
        }
    }
    button:hover {
        cursor: pointer;
        background: #0a2998;
    }
    ul {
        margin-left: 20px;
    }
`;

const ButtonBox = styled(Wrapper)`
    @media ${({theme}) => theme.mediaQueries.breakpointLg} {
        display: flex;
        justify-content: center;
    }
`;

const Vantaggi = () => {
    
    return (
        <WrapperSection>
            <Container>
                <Row>
                    {renderColumnImg()}
                    {renderColumnTitle()}
                </Row>
            </Container>
        </WrapperSection>
    );

    function renderColumnImg() {
        return (
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <BoxImage>
                    <img src={getLocalImage('section_vantaggi_2.png')} alt={'Vantaggi G4'} />
                </BoxImage>
            </Col>
        )
    }

    function renderColumnTitle(){
        const items = Constants.VANTAGGI_LIST.map((x, i) => (
            <li key={i}>
                {x}
            </li>
        ));

        return (
            <Col xl={6} lg={6} md={6} sm={12} xs={12} align={'center'}>
                <BoxTitle>
                    <TitleH4 dangerouslySetInnerHTML={{__html: Constants.VANTAGGI_TITLE}}/>
                    <ul>
                        {items}
                    </ul>
                    <ButtonBox>
                        <button><a  href="#box-form">RICHIEDI INFORMAZIONI</a></button>
                    </ButtonBox>
                </BoxTitle>
            </Col>
        )
    }
}
export default Vantaggi;