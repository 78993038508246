import React from 'react';
import { Wrapper } from '../ui/Wrapper/Wrapper';
import { Text } from '../ui/Typography/Typography';
import styled from 'styled-components';
import { TitleH6 } from '../ui/Title/Title';
import { getLocalImage } from '../../helpers/getLocalImages';
import { Container, Row, Col } from '../ui/GridSystem/GridSystem';

const WrapperCard = styled(Wrapper)`
    border-radius: 20px;
    margin: 30px 0;
    
    button {
        background: ${({theme}) => theme.colors.secondary};
        display: flex;
        border-radius: 40px;
        border: none;
        justify-content: center;
        a {
            pointer-events: auto;
            display: flex;
            align-items: center;
            padding: 20px 30px;
            color: white;
            text-decoration: none;
            font-weight: bold;
        }
    }
    button:hover {
        cursor: pointer;
        background: #0a2998;
    }
`;

const Card = styled(Wrapper)`
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const BoxImage = styled(Wrapper)`
    text-align: center;
    img {
        max-width: 80px;
    }
`;
const BoxText = styled(Wrapper)`
    text-align: center;
    min-width: 80%;
    margin-top: 10px;
    a {
        color: #0a2998;
    }
    a:hover {
        color: ${({theme}) => theme.colors.main};
    }
`;
const BoxButton = styled(Wrapper)`
    margin-top: 10px;
`;

interface BulletPointProps {
    img: string
    title: string
    text: string
}

const BulletPoint: React.FC<BulletPointProps> = (props) => {
    return (
        <WrapperCard padding={'30px'} margin={'10px 0px'}>
            <Container>
                <Row>
                    {renderBodyCard()}
                </Row>
            </Container>
        </WrapperCard>
    );

    function renderBodyCard(){
        return (
            <Col xl={12} lg={12} md={12} sm={12}>
            <Card>
                <BoxImage>
                    <img src={getLocalImage(props.img)} alt={'Vigilanza'} />
                </BoxImage>
                <BoxText>
                    <TitleH6 margin={'0 0 20px 0'}>{props.title}</TitleH6>
                    <Text dangerouslySetInnerHTML={{__html: props.text}}/>
                </BoxText>
                <BoxButton>
                    <button><a  href="#box-form">RICHIEDI INFORMAZIONI</a></button>
                </BoxButton>
            </Card>
            </Col>
        )
    }
}

export default BulletPoint;
