import { css } from 'styled-components';

export interface IFlexProps {
    flex?: boolean
    direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
    justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around'
    align?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'first baseline' | 'last baseline'
    wrap?: 'wrap' | 'nowrap' | 'wrap-reverse'
}

export const FLEX_PROPS = css<IFlexProps>`
    
    ${({flex}) => flex && css`
        display: flex;
    `};

    ${({wrap}) => wrap && css`
        flex-wrap: wrap;
    `};

    ${({direction}) => direction && css`
        flex-direction: ${direction};
    `};

    ${({justify}) => justify && css`
        justify-content: ${justify};
    `};

    ${({align}) => align && css`
        align-items: ${align};
    `};
`;