import React from 'react';
import { Wrapper } from '../ui/Wrapper/Wrapper';
import styled from 'styled-components';
import { Row, Col, Container } from '../ui/GridSystem/GridSystem';
import { TitleH1 } from '../ui/Title/Title';
import { Text } from '../ui/Typography/Typography';
import Constants from '../../config/Constants';
import Form from '../Form/Form';
import { getLocalImage } from '../../helpers/getLocalImages';


const HeroStyled = styled(Wrapper)`
    padding: 30px 0px;
    background-position: left center;
    width: 100%;
    margin-top: 80px;
`;

const BoxForm = styled('div')`
    margin: 20px 0;
`;

const BoxTitle = styled('div')`
    width: 100%;
`;

const Hero = () => {
    
    return (
        <HeroStyled bgImage={getLocalImage('hero_lead_a.png')} bgCover>
            <Container>
                <Row>
                    {renderColumnTitle()}
                    {renderColumnForm()}
                </Row>
            </Container>
        </HeroStyled>
    );

    function renderColumnTitle(){
        return (
            <Col xl={6} lg={6} md={6} sm={12} xs={12} align={'center'}>
                <BoxTitle>
                    <TitleH1 white dangerouslySetInnerHTML={{__html: Constants.HERO_TITLE}}/>
                    <Text large white margin="20px 0 0 0" dangerouslySetInnerHTML={{__html: Constants.HERO_SUBTITLE}}/>
                </BoxTitle>
            </Col>
        )
    }

    function renderColumnForm() {
        return (
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <BoxForm>
                    <Form />
                </BoxForm>
            </Col>
        )
    }
    
}
export default Hero;
