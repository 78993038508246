import React from "react";
import Hero from "../components/Hero/Hero";
import Vantaggi from "../components/SectionVantaggi/SectionVantaggi";
import Servizi from "../components/SectionServices/SectionServices";
import Numbers from "../components/SectionNumbers/SectionNumbers";
import AboutUs from "../components/SectionAboutUs/SectionAboutUs";
import BulletPoints from "../components/SectionBulletPoints/SectionBulletPoints";
import ContactButton from "../components/ContactButton/ContactButton";
import { Footer, Header } from "../components/navigationals";
import CarouselLoghi from "../components/SectionLoghiCarousel/SectionLoghiCarousel";


const PageHome = () => {
    return (
        <>  
            <Header />
            <Hero />
            <Vantaggi />
            <Servizi />
            <Numbers />
            <BulletPoints />
            <AboutUs />
            <CarouselLoghi />
            <ContactButton />
            <Footer />
        </>
    );
}

export default PageHome;