import React from 'react'
import styled  from 'styled-components';
import { Wrapper } from '../ui/Wrapper/Wrapper';
import { FaPhoneAlt } from 'react-icons/fa';

const ContactButtonBox = styled(Wrapper)`
    position: fixed;
    bottom: 25px;
    right: 25px;
    color: white;
    background: ${({theme}) => theme.colors.secondary};
    border-radius: 50px;
    border: none;
    padding: 20px 22px 15px 22px;
    a {
        pointer-events: auto;
        color: white;
        font-weight: bold;
        font-size: 2em;
    }
    &:hover {
        cursor: pointer;
        background: #0a2998;
    }
`;

const ContactButton = () => {
    return (
        <ContactButtonBox>
                {renderButton()}
        </ContactButtonBox>
    );

    function renderButton(){
        return(
        // <button><Text white large><FaPhoneAlt /></Text></button>
        <a href="tel:+390303512311"> <FaPhoneAlt /></a>
        )
    }
}

export default ContactButton;