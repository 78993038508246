import React from 'react';
import styled from 'styled-components';
import { getLocalImage } from '../../helpers/getLocalImages';
import { Container, Row, Col } from '../ui/GridSystem/GridSystem';
import { Wrapper } from '../ui/Wrapper/Wrapper';

const HeaderStyled = styled('header')`
    background: #f7f7f7;
    width: 100%;
    min-height: 80px;
    position: fixed;
    top: 0;
    button {
        background: ${({theme}) => theme.colors.secondary};
        display: flex;
        border-radius: 40px;
        border: none;
        width: 250px;
        justify-content: center;
        a {
            pointer-events: auto;
            display: flex;
            align-items: center;
            padding: 20px 30px;
            color: white;
            text-decoration: none;
            font-weight: bold;
        }
        }
        button:hover {
            cursor: pointer;
            background: #0a2998;
        }
`;

const WrapperImage = styled(Wrapper)`
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    img {
        width: 180px;
    }
    @media ${({theme}) => theme.mediaQueries.breakpointSm} {
        .logo_1 {
            width: 130px;
        }
    }
`;

const WrapperButton = styled(Wrapper)`
    width: 100%;
    display: flex;
    justify-content: end;
    margin: 10px 0;
`;

function Header() {
    return (
        <HeaderStyled>
            <Container>
                <Row>
                        {renderLogo()}
                        {renderButton()}
                </Row>
            </Container>
        </HeaderStyled>
    );

    function renderLogo(){
        return (
            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <WrapperImage>
                    <img src={getLocalImage('g4_logo_1.png')} alt="g4-logo" className={'logo_1'}/>
                </WrapperImage>
            </Col>
        )
    }

    function renderButton(){
        return (
            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <WrapperButton>
                    <button><a  href="#box-form">RICHIEDI INFORMAZIONI</a></button>
                </WrapperButton>
            </Col>
        )
    }
};


export default Header; 

