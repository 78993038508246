import React from 'react';
import { ThemeProvider, DefaultTheme } from 'styled-components';

interface ITitle {
    desk: string
    mob: string
}

export type ThemeColorType = 'main' | 'secondary' | 'dark' | 'grey' | 'light' | 'lighter' | 'text'
  | 'link' | 'success' |'error' | 'warning' | 'lighterOpacity'

declare module 'styled-components' {
  export interface DefaultTheme {
    borderRadius: string
    shadow: {
        small: string,
        medium: string,
        large: string
    }
    colors: {[key in ThemeColorType]: string}
    fonts: string[]
    fontSizes: {
        small: string,
        medium: string,
        large: string,
        h1: ITitle,
        h2: ITitle,
        h3: ITitle,
        h4: ITitle,
        h5: ITitle,
        h6: ITitle,
        p: string
    }
    mediaQueries: {
        breakpointXs: string,
        breakpointSm: string,
        breakpointMd: string,
        breakpointLg: string,
        breakpointXl: string
    }
  }
}

const theme: DefaultTheme = {
    borderRadius: '6px',

    shadow: {
        small: '0 5px 15px 0 rgba(0,0,0,0.06)',
        medium: '0 20px 40px 0 rgba(0,0,0,0.06)',
        large: '0 20px 60px 0 rgba(0,0,0,0.06)'
    },

  	colors: {
    	main: '#484848',
        secondary: '#0d1636',
        dark: '#808080',
        grey: '#9a9a9a',
        light: '#cccccc',
        lighter: '#f0f1f4',
        text: '#13121B',
        link: '#e8bc9e',
        success: '#19e68c',
        error: '#a00000',
        warning: '#ffa000',
        lighterOpacity: 'rgba(240, 241, 244, .3)'
    },
    
    fonts: ['Raleway', 'sans-serif'],

    fontSizes: {
        small: '12px',
        medium: '15px',
        large: '20px',
        h1: {desk: '38px', mob: '26px'},
        h2: {desk: '32px', mob: '24px'},
        h3: {desk: '28px', mob: '21px'},
        h4: {desk: '26px', mob: '19px'},
        h5: {desk: '22px', mob: '18px'},
        h6: {desk: '18px', mob: '16px'},
        p: '14px'
    },

    mediaQueries: {
        breakpointXs: 'only screen and (max-width: 420px)',
        breakpointSm: 'only screen and (max-width: 576px)',
        breakpointMd: 'only screen and (max-width: 768px)',
        breakpointLg: 'only screen and (max-width: 1025px)',
        breakpointXl: 'only screen and (min-width: 1170px)'
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Theme = ({children}: any) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;