import React from 'react';
import { Wrapper } from '../ui/Wrapper/Wrapper';
import styled from 'styled-components';
import { Container, Row, Col } from '../ui/GridSystem/GridSystem';
import { TitleH4 } from '../ui/Title/Title';
import { Text } from '../ui/Typography/Typography';
import Constants from '../../config/Constants';


const WrapperSection = styled(Wrapper)`
    width: 100%;
    margin: 50px 0;
    padding: 50px 0;
`;
const BoxNumbers = styled(Wrapper)`
    text-align: center;
    padding: 10px;
    h4 {
        color: #0a2998;
    }
`;

const Numbers = () => {
    
    return (
        <WrapperSection>
            <Container>
                <Row>
                    {renderNumbers()}
                </Row>
            </Container>
        </WrapperSection>
    );

    function renderNumbers(){
        return Constants.NUMBERS.map((x, i) => i < 6 ? (
            <Col key={i} xl={2} lg={2} md={2} sm={4} xs={6}>
                <BoxNumbers>
                    <TitleH4>{x.title}</TitleH4>
                    <Text large>{x.subtitle}</Text>
                </BoxNumbers>
            </Col>
        ): null);
    }
}

export default Numbers;